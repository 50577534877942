
import Vue from 'vue';

export default Vue.extend({
  created() {
    const { query, path } = this.$route;
    this.$router.replace({ path, query });
  },
  render(h) {
    return h();
  },
});
